/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

import logoBlack from "~assets/images/logo-black.png";
import logoWhite from "~assets/images/logo-white.png";

const Header = () => {
  const {
    cart,
    cartActive,
    setCartActive,
    pathname,
    menuActive,
    setMenuActive
  } = useContext(AppContext);
  const { scrollTop, windowWidth } = useContext(DocumentContext);

  const toggleCart = () => {
    setCartActive(!cartActive);
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  //

  let backgroundColor = `white`;
  let foregroundColor = `black`;

  // if (pathname === `/` && scrollTop < windowWidth * 0.475) {
  if (pathname === `/` && scrollTop < 72) {
    backgroundColor = `transparent`;
    foregroundColor = `white`;
  }

  let cartCount = 0;

  cart.forEach(cartItem => {
    cartCount += cartItem.quantity;
  });

  //

  return (
    <header
      className={`header ${cartActive ? ` cart-active` : ``} ${
        menuActive ? ` menu-active` : ``
      } w-full fixed top-0 right-0 left-0 z-40 flex items-center justify-center bg-${backgroundColor} border-b-${foregroundColor}`}
    >
      <nav className="grid">
        <div className="animation-appear-down-slow animation-delay-5 grid-end-2 flex items-center justify-between">
          <Link to="/">
            <img
              className="header__logo relative block"
              src={foregroundColor === `white` ? logoWhite : logoBlack}
              alt="Parlor Tea"
            />
          </Link>
        </div>

        <div className="grid-end-10 flex items-center justify-end">
          <div className="flex items-center">
            <button
              type="button"
              className="animation-appear-down-slow animation-delay-4 mr-6"
              onClick={toggleCart}
            >
              <span
                className={`b1 text-${
                  cartActive || menuActive ? `black` : foregroundColor
                }`}
              >
                Cart ({cartCount})
              </span>
            </button>

            <button
              type="button"
              className={`header__menu ${
                cartActive || menuActive ? `crossed` : ``
              } animation-appear-down-slow animation-delay-5 relative block`}
              onClick={toggleMenu}
            >
              <div className="relative flex flex-col items-center justify-center">
                <div
                  className="header__menu__line header__menu__line--0 w-full mt-1 mb-1 pointer-events-none"
                  style={{
                    backgroundColor:
                      cartActive || menuActive ? `black` : foregroundColor
                  }}
                ></div>
                <div
                  className="header__menu__line header__menu__line--1 w-full mt-1 mb-1 pointer-events-none"
                  style={{
                    backgroundColor:
                      cartActive || menuActive ? `black` : foregroundColor
                  }}
                ></div>
              </div>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
