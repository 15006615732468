// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-page-js": () => import("./../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-collection-page-js": () => import("./../src/templates/collection-page.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-collections-page-js": () => import("./../src/templates/collections-page.js" /* webpackChunkName: "component---src-templates-collections-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-products-page-js": () => import("./../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

