/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import { getPriceByCurrency, parseProducts } from "~utils/shopify";
import { useKeyPress } from "~utils/hooks";
import ScreenHeight from "~components/ScreenHeight";

const query = graphql`
  query Nav {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            shopifyHandle
            collection
            priority
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            presentmentPrices {
              edges {
                node {
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
              inventory_item_id
              title
            }
          }
        }
      }
    }
  }
`;

const Nav = () => {
  const {
    setActiveCategory,
    activeCurrency,
    activeCurrencySymbol,
    cart,
    cartActive,
    setCartActive,
    menuActive,
    setMenuActive,
    inventoryFetched,
    setInventoryFetched,
    refreshInventoryCache,
    //
    checkout,
    decreaseQuantityByCartIndex,
    increaseQuantityByCartIndex,
    removeFromCartByIndex
  } = useContext(AppContext);
  const { device, scrollTop } = useContext(DocumentContext);

  const [expanded, setExpanded] = useState(null);

  const mobileScrollRef = useRef();

  let isSafari = false;
  let isIphone = false;
  let isMobileIosSafari = false;

  if (typeof window !== `undefined`) {
    isSafari = navigator.userAgent.indexOf(`Safari`) !== -1;
    isIphone = navigator.userAgent.indexOf(`iPhone`) !== -1;
    isMobileIosSafari = isSafari && isIphone;
  }

  //

  const close = () => {
    setCartActive(false);
    setExpanded(null);
    setMenuActive(false);
  };

  //

  const escKeyPressed = useKeyPress(`Escape`);

  //

  const handleTouchMove = e => {
    if (!mobileScrollRef?.current) {
      return;
    }

    const touchTargetWithinBounds = mobileScrollRef?.current?.contains(
      e.target
    );

    if (!touchTargetWithinBounds) {
      e.preventDefault();
    }
  };

  //

  useEffect(() => {
    if (!device || device === `desktop`) {
      return () => {};
    }

    if (cartActive) {
      if (mobileScrollRef && isMobileIosSafari) {
        window.addEventListener(`touchmove`, handleTouchMove, {
          passive: false
        });
      }
    } else {
      window.removeEventListener(`touchmove`, handleTouchMove, {
        passive: false
      });
    }

    return () =>
      window.removeEventListener(`touchmove`, handleTouchMove, {
        passive: false
      });
  }, [cartActive]);

  //

  useEffect(() => {
    if (device === `desktop`) {
      close();
    }
  }, [escKeyPressed]);

  useEffect(() => {
    if (device === `desktop`) {
      close();
    }
  }, [scrollTop]);

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const products = parseProducts(data);

        if (!inventoryFetched) {
          // setTimeout fixes the static query/render lifecycle warning
          setTimeout(() => {
            setInventoryFetched(true);
            refreshInventoryCache(products, data.allShopifyAdminProduct);
          });
        }

        const navCart = [];

        let cartCount = 0;
        let cartTotal = 0;

        //

        cart.forEach(cartItem => {
          products.forEach(productItem => {
            productItem.variants.forEach(productItemVariant => {
              if (productItemVariant.id === cartItem.variantId) {
                const lineItemCost =
                  getPriceByCurrency(productItemVariant, activeCurrency) *
                  cartItem.quantity;

                cartTotal += lineItemCost;
                cartCount += cartItem.quantity;

                navCart.push({
                  id: `${cartItem.variantId}`,
                  image: cartItem.image || productItemVariant.image.originalSrc,
                  quantity: cartItem.quantity,
                  //
                  price: `${activeCurrencySymbol}${parseFloat(
                    lineItemCost
                  ).toFixed(2)}`,
                  slug: productItem.slug,
                  selectedOptions: productItemVariant.selectedOptions,
                  title: productItem.title,
                  variantTitle: productItemVariant.title
                });
              }
            });
          });
        });

        //

        return (
          <ScreenHeight
            className={`nav ${cartActive ? `cart-active z-50` : ``} ${
              menuActive ? `menu-active z-50` : ``
            } w-screen fixed top-0 right-0 bottom-0 left-0 flex items-center justify-between z-40 pointer-events-none`}
          >
            <div
              role="presentation"
              className={`transition-opacity w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 ${
                cartActive || menuActive
                  ? `pointer-events-auto opacity-25`
                  : `pointer-events-none opacity-0`
              } bg-black`}
              onClick={close}
            ></div>

            <div
              className={`nav__menu ${
                menuActive ? `pointer-events-auto` : ``
              } transition-transform h-full absolute top-0 right-0 z-20 bg-white text-black`}
            >
              <div className="nav__meta w-full relative flex items-center justify-end px-8">
                <button
                  type="button"
                  className="header__menu crossed relative block"
                  onClick={close}
                >
                  <div className="relative flex flex-col items-center justify-center">
                    <div
                      className="header__menu__line header__menu__line--0 w-full mt-1 mb-1 pointer-events-none"
                      style={{
                        backgroundColor: `black`
                      }}
                    ></div>
                    <div
                      className="header__menu__line header__menu__line--1 w-full mt-1 mb-1 pointer-events-none"
                      style={{
                        backgroundColor: `black`
                      }}
                    ></div>
                  </div>
                </button>
              </div>

              {menuActive && (
                <ul className="relative pt-8 xs:pt-8 px-12 xs:px-6">
                  <li
                    className="animation-appear-right-slow hover-underline f3"
                    style={{ animationDelay: `100ms` }}
                  >
                    <Link to="/" className="block py-2" onClick={close}>
                      Home
                    </Link>
                  </li>

                  {/* // */}

                  <li
                    className="animation-appear-right-slow hover-underline f3"
                    style={{ animationDelay: `150ms` }}
                  >
                    <button
                      type="button"
                      className="block py-2"
                      onClick={() =>
                        setExpanded(expanded === null ? `Shop` : null)
                      }
                    >
                      Shop
                    </button>
                  </li>

                  {expanded === `Shop` && (
                    <li
                      className="animation-appear-right-slow hover-underline f4"
                      style={{ animationDelay: `50ms` }}
                    >
                      <Link
                        to="/products"
                        className="block py-1"
                        onClick={close}
                      >
                        All products
                      </Link>
                    </li>
                  )}

                  {expanded === `Shop` && (
                    <li
                      className="animation-appear-right-slow hover-underline f4"
                      style={{ animationDelay: `100ms` }}
                    >
                      <Link
                        to="/collections/japanese-tea"
                        className="block py-1"
                        onClick={close}
                      >
                        Japanese Tea
                      </Link>
                    </li>
                  )}

                  {expanded === `Shop` && (
                    <li
                      className="animation-appear-right-slow mb-8 hover-underline f4"
                      style={{ animationDelay: `150ms` }}
                    >
                      <Link
                        to="/collections/teaware"
                        className="block py-1"
                        onClick={close}
                      >
                        Teaware
                      </Link>
                    </li>
                  )}

                  {/* // */}

                  <li
                    className="animation-appear-right-slow hover-underline f3"
                    style={{ animationDelay: `200ms` }}
                  >
                    <button
                      type="button"
                      className="block py-2"
                      onClick={() =>
                        setExpanded(expanded === null ? `Blog` : null)
                      }
                    >
                      Blog
                    </button>
                  </li>

                  {expanded === `Blog` && (
                    <li
                      className="animation-appear-right-slow hover-underline f4"
                      style={{ animationDelay: `50ms` }}
                    >
                      <Link
                        to="/blog"
                        className="block py-1"
                        onClick={() => {
                          setActiveCategory(null);
                          close();
                        }}
                      >
                        All Stories
                      </Link>
                    </li>
                  )}

                  {expanded === `Blog` && (
                    <li
                      className="animation-appear-right-slow hover-underline f4"
                      style={{ animationDelay: `100ms` }}
                    >
                      <Link
                        to="/blog"
                        className="block py-1"
                        onClick={() => {
                          setActiveCategory(`Tea School`);
                          close();
                        }}
                      >
                        Tea School
                      </Link>
                    </li>
                  )}

                  {expanded === `Blog` && (
                    <li
                      className="animation-appear-right-slow mb-8 hover-underline f4"
                      style={{ animationDelay: `150ms` }}
                    >
                      <Link
                        to="/blog"
                        className="block py-1"
                        onClick={() => {
                          setActiveCategory(`Lifestyle`);
                          close();
                        }}
                      >
                        Lifestyle
                      </Link>
                    </li>
                  )}

                  {/* // */}

                  <li
                    className="animation-appear-right-slow hover-underline f3"
                    style={{ animationDelay: `250ms` }}
                  >
                    <Link to="/about" className="block py-2" onClick={close}>
                      About Us
                    </Link>
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`nav__cart ${
                cartActive ? `pointer-events-auto` : ``
              } transition-transform h-full absolute top-0 right-0 z-20 flex flex-col items-stretch bg-white text-black`}
            >
              <div
                className="nav__meta w-full relative flex items-center justify-between px-8"
                style={{ flex: `0 0 auto` }}
              >
                <p className="b1">Cart items ({cartCount})</p>

                <button
                  type="button"
                  className="header__menu crossed relative block"
                  onClick={close}
                >
                  <div className="relative flex flex-col items-center justify-center">
                    <div
                      className="header__menu__line header__menu__line--0 w-full mt-1 mb-1 pointer-events-none"
                      style={{
                        backgroundColor: `black`
                      }}
                    ></div>
                    <div
                      className="header__menu__line header__menu__line--1 w-full mt-1 mb-1 pointer-events-none"
                      style={{
                        backgroundColor: `black`
                      }}
                    ></div>
                  </div>
                </button>
              </div>

              {/* // */}

              {(device === `desktop` && (
                <>
                  {(cartActive && navCart?.[0] && (
                    <div className="w-full h-full flex flex-col items-stretch">
                      <ul className="nav__cart__list h-full relative overflow-y-scroll pt-6 xs:pt-0 px-8">
                        {navCart.map((cartItem, cartItemIndex) => {
                          return (
                            <li
                              key={cartItem.id}
                              className="nav__cart__item relative flex items-stretch pt-8 pb-8 border-b-black"
                            >
                              <div className="nav__cart__item__image relative block">
                                <figure className="square">
                                  <img
                                    className="w-full absolute transform-center"
                                    src={cartItem.image}
                                    alt={cartItem.title}
                                  />
                                </figure>
                              </div>

                              <article className="w-full relative flex flex-col justify-between pl-4">
                                <div className="relative flex items-start justify-between">
                                  <header>
                                    <h2 className="b2 font-medium">
                                      {cartItem.title}
                                    </h2>

                                    {cartItem?.variantTitle &&
                                      cartItem.variantTitle.toLowerCase() !==
                                        `default title` && (
                                        <h3 className="b3">
                                          {cartItem.variantTitle}
                                        </h3>
                                      )}
                                  </header>

                                  <button
                                    type="button"
                                    className="-mt-1 hover-underline"
                                    onClick={() =>
                                      removeFromCartByIndex(cartItemIndex)
                                    }
                                  >
                                    <span className="b2">remove</span>
                                  </button>
                                </div>

                                <div className="relative flex items-center justify-between -mb-1">
                                  <div className="flex">
                                    <button
                                      type="button"
                                      className="pr-6"
                                      onClick={() => {
                                        decreaseQuantityByCartIndex(
                                          cartItemIndex
                                        );
                                      }}
                                    >
                                      <span className="b3 font-medium">-</span>
                                    </button>

                                    <div className="relative text-center">
                                      <span className="b3 font-medium">
                                        {cartItem.quantity}
                                      </span>
                                    </div>

                                    <button
                                      type="button"
                                      className="pl-6"
                                      onClick={() =>
                                        increaseQuantityByCartIndex(
                                          cartItemIndex
                                        )
                                      }
                                    >
                                      <span className="b3 font-medium">+</span>
                                    </button>
                                  </div>

                                  <h3 className="mt-2 font-medium b3">
                                    {cartItem.price}
                                  </h3>
                                </div>
                              </article>
                            </li>
                          );
                        })}
                      </ul>

                      <div className="w-full relative pt-6 pb-6 px-6">
                        <div className="relative flex justify-between">
                          <h3 className="b1 font-medium">Subtotal</h3>

                          <h3 className="b1 font-medium">
                            ${parseFloat(cartTotal).toFixed(2)}
                          </h3>
                        </div>

                        <p className="mt-5 xs:mt-3 b2">
                          {cartTotal >= 75
                            ? `Free AU shipping.`
                            : `You are $${75 -
                                cartTotal} away from free AU shipping.`}
                        </p>

                        <p className="b2">
                          Shipping, taxes and discounts calculated at checkout.
                        </p>

                        <Link to="/products" onClick={close}>
                          <Button
                            className="w-full mt-8 xs:mt-4"
                            color="black"
                            transparent
                          >
                            <span className="b1">Shop more</span>
                          </Button>
                        </Link>

                        <Button
                          className="w-full mt-2"
                          color="black"
                          onClick={checkout}
                        >
                          <span className="b1">Check out</span>
                        </Button>
                      </div>
                    </div>
                  )) || (
                    <div className="animation-appear-down-slow animation-delay-2 h-full relative px-8">
                      <div className="nav__cart__empty relative">
                        <h3 className="pt-20 pb-16 border-b-black b1 text-black">
                          You don’t have anything in your
                          <br />
                          cart yet.
                        </h3>
                      </div>

                      <div className="w-full relative">
                        <Link
                          to="/products"
                          className="w-full block"
                          onClick={close}
                        >
                          <Button
                            className="w-full relative block"
                            color="black"
                          >
                            <span className="b1">Start shopping</span>
                          </Button>
                        </Link>

                        <p className="mt-4 b2 opacity-0">
                          Shipping, taxes and discounts calculated at checkout.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )) || (
                <>
                  <div
                    style={{
                      opacity: cartActive && navCart?.[0] ? 1 : 0,
                      pointerEvents:
                        cartActive && navCart?.[0] ? `auto` : `none`
                    }}
                    className="w-full h-full flex flex-col items-stretch overflow-hidden"
                  >
                    <ul
                      ref={mobileScrollRef}
                      style={{ flex: `1 1` }}
                      className="nav__cart__list w-full relative overflow-y-scroll pt-6 xs:pt-0 px-8"
                    >
                      {navCart.map((cartItem, cartItemIndex) => {
                        return (
                          <li
                            key={cartItem.id}
                            className="nav__cart__item relative flex pt-8 pb-8 border-b-black"
                          >
                            <div className="nav__cart__item__image relative block">
                              <figure className="square">
                                <img
                                  className="w-full absolute transform-center"
                                  src={cartItem.image}
                                  alt={cartItem.title}
                                />
                              </figure>
                            </div>

                            <article className="w-full relative flex flex-col justify-between pl-4">
                              <div className="relative flex items-start justify-between">
                                <header>
                                  <h2 className="b2 font-medium">
                                    {cartItem.title}
                                  </h2>

                                  {cartItem?.variantTitle &&
                                    cartItem.variantTitle.toLowerCase() !==
                                      `default title` && (
                                      <h3 className="b3">
                                        {cartItem.variantTitle}
                                      </h3>
                                    )}
                                </header>

                                <button
                                  type="button"
                                  className="-mt-1 hover-underline"
                                  onClick={() =>
                                    removeFromCartByIndex(cartItemIndex)
                                  }
                                >
                                  <span className="b2">remove</span>
                                </button>
                              </div>

                              <div className="relative flex items-center justify-between -mb-1">
                                <div className="flex">
                                  <button
                                    type="button"
                                    className="pr-6"
                                    onClick={() => {
                                      decreaseQuantityByCartIndex(
                                        cartItemIndex
                                      );
                                    }}
                                  >
                                    <span className="b3 font-medium">-</span>
                                  </button>

                                  <div className="relative text-center">
                                    <span className="b3 font-medium">
                                      {cartItem.quantity}
                                    </span>
                                  </div>

                                  <button
                                    type="button"
                                    className="pl-6"
                                    onClick={() =>
                                      increaseQuantityByCartIndex(cartItemIndex)
                                    }
                                  >
                                    <span className="b3 font-medium">+</span>
                                  </button>
                                </div>

                                <h3 className="mt-2 font-medium b3">
                                  {cartItem.price}
                                </h3>
                              </div>
                            </article>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="w-full relative pt-6 pb-6 px-6">
                      <div className="relative flex justify-between">
                        <h3 className="b1 font-medium">Subtotal</h3>

                        <h3 className="b1 font-medium">
                          ${parseFloat(cartTotal).toFixed(2)}
                        </h3>
                      </div>

                      <p className="mt-5 xs:mt-3 b2">
                        {cartTotal >= 75
                          ? `Free AU shipping.`
                          : `You are $${75 -
                              cartTotal} away from free AU shipping.`}
                      </p>

                      <p className="b2">
                        Shipping, taxes and discounts calculated at checkout.
                      </p>

                      <Link to="/products" onClick={close}>
                        <Button
                          className="w-full mt-8 xs:mt-4"
                          color="black"
                          transparent
                        >
                          <span className="b1">Shop more</span>
                        </Button>
                      </Link>

                      <Button
                        className="w-full mt-2"
                        color="black"
                        onClick={checkout}
                      >
                        <span className="b1">Check out</span>
                      </Button>
                    </div>
                  </div>

                  {!navCart?.[0] && (
                    <div className="animation-appear-down-slow animation-delay-2 w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 pointer-events-none px-8">
                      <div className="nav__cart__empty relative mt-20 bg-white">
                        <h3 className="pt-12 pb-16 text-center border-b-black b1 text-black">
                          You don’t have anything in your
                          <br />
                          cart yet.
                        </h3>
                      </div>

                      <div className="w-full relative z-10 bg-white">
                        <Link
                          to="/products"
                          className="w-full block pointer-events-auto"
                          onClick={close}
                        >
                          <Button
                            className="w-full relative block"
                            color="black"
                          >
                            <span className="b1">Continue shopping</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </ScreenHeight>
        );
      }}
    />
  );
};

export default Nav;
