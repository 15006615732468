import React from "react";
import PropTypes from "prop-types";
import { useWindowDimensions } from "~hooks";

const ScreenHeight = ({ children, className }) => {
  const windowHeight = useWindowDimensions().windowSize.height;

  //

  return (
    <section
      className={`screen-height ${className}`}
      style={{ height: `${windowHeight}px` }}
    >
      {children}
    </section>
  );
};

ScreenHeight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default ScreenHeight;
